'use client';
import React, { useRef, useEffect } from 'react';

function Svg(props: any) {
  const { svg, className } = props;
  const svgWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (svgWrapperRef.current) {
      svgWrapperRef.current.innerHTML = svg;
    }
  }, [svg]);

  return <div className={className} ref={svgWrapperRef}></div>;
}

export default Svg;
