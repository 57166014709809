'use client';
import React, { useState } from 'react';

function HomeTagLeftRight(props: any) {
  const { lang } = props;
  const [currentMargin, setCurrentMargin] = useState(null);
  const [maxMargin, setMaxMargin] = useState(null);
  const [showArrowTagsRight, setShowArrowTagsRight] = useState(true);

  function moveTagsRight() {
    if (-currentMargin < maxMargin) {
      let newMargin =
        currentMargin - typeof window !== 'undefined' && window.innerWidth + 50;
      swiperContainer.current.style.marginLeft = `${newMargin}px`;
      setCurrentMargin(newMargin);
    } else {
      setShowArrowTagsRight(false);
    }
  }
  function moveTagsLeft() {
    if (currentMargin) {
      setShowArrowTagsRight(true);
      let newMargin =
        currentMargin + typeof window !== 'undefined' && window.innerWidth - 50;
      swiperContainer.current.style.marginLeft = `${newMargin}px`;
      if (newMargin) {
        setCurrentMargin(newMargin);
      } else {
        setCurrentMargin(null);
      }
    }
    //  else {
    // }
  }
  return (
    <>
      {currentMargin && (
        <div
          onClick={moveTagsLeft}
          className="swiper-button-prev waves-effect"
          tabIndex="0"
          role="button"
          aria-label="Next slide"
          aria-disabled="false"
        ></div>
      )}
      {showArrowTagsRight && (
        <div
          onClick={moveTagsRight}
          className="swiper-button-next waves-effect"
          tabIndex="0"
          role="button"
          aria-label="Next slide"
          aria-disabled="false"
        ></div>
      )}
    </>
  );
}

export default HomeTagLeftRight;
